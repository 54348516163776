import React from 'react';

import Layout from '../components/Layout';
import pic4 from '../assets/images/bg29.jpg';
import pic5 from '../assets/images/test3.jpg';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Tips & Tricks</h2>
          <p>Take your cooking to the next level</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">DON’T RELY ON RECIPE BOOK COOKING TIMES</h3>
          <p>
          Don’t rely only on the cooking times prescribed in recipes books because they don’t always guarantee properly cooked meat. 
          Recipes can’t account for significant changes in cooking time caused by small variations in meat thickness, 
          temperature, and the temperature of grills, ovens, pans, etc.
          </p>
          
          <h3 className="major">BRING MOST MEATS TO ROOM TEMPERATURE BEFOREHAND</h3>
          <p>
          
          Don’t lay your meat on a hot pan directly from the refrigerator. Bring most meats to room temperature before cooking. 
          This reduces cooking times significantly and prevents the surface being overcooked while undercooking the inside. 
          The only time you can cook meat cuts directly from the refrigerator is when cuts are thin, to give the surface time to brown.
          </p>
          <div className="box alt">
							<div className="row gtr-uniform">
								<div className="col-8"><span className="image fit"><img src={pic5} alt="" /></span></div>
								<div className="col-4"><span className="image fit"><img src={pic4} alt="" /></span></div>
							</div>
						</div>

          <h3 className="major">COOK MOST MEATS TWICE</h3>
          <p>
          <b>First: </b> Cook at a very high heat in order to kill surface bacteria, and also to brown/flavor the meat surface.<br></br>
          <b>Second: </b> Finish with low and controlled heat to cook the meat slowly and gently while preserving its moistness and tenderness. 
          Keep the finishing cook-through as close as possible to the final inner temperature you want. 
          Remember that the meat continues cooking when you remove it from the stove or oven, so take this time into account, especially for thin cuts.
          </p>
          <h3 className="major"> WHEN COOKING GROUND MEATS AND FRESH SAUSAGES</h3>
          
          <p>In Romania, sausages are typically cooked on a grill. 
            We encourage you to continue following this tradition and highly recommend that our sausages be grilled as well. 
            This ensures that our meat has the best possible taste and texture. Before grilling, Your sausages should be made taut. 
            Grill the sausages at a temperature of 400 degrees Fahrenheit, for approximately 15 minutes, or until they reach an internal temperature of 160 degrees Fahrenheit. 
            The USDA recommends that you verify the internal temperature of the sausage is 160 degrees before consuming.
          </p>
          
         


          
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
